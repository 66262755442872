import React, { useEffect } from "react";

import gsap from "gsap";

const IndexPage = (props) => {
  const { setScroll } = props;

  const tl = gsap.timeline();

  useEffect(() => {
    tl.to("#header", {
      opacity: 1,
      y: 0,
      duration: 0.25,
      delay: 0,
    });

    setScroll(false);
  }, []);

  return (
    <section className="privacypolicy container">
      <h1>Política de cookies</h1>
      <p>
        A devstudio utiliza nos seus sites cookies que permitem melhorar o
        desempenho e a experiência do utilizador conforme descrito abaixo.
      </p>
      <h2>Conhecer os cookies</h2>
      <p>
        Os cookies são pequenos ficheiros de texto com informação relevante que
        o seu dispositivo de acesso (computador, telemóvel/smartphone ou tablet)
        carrega, através do navegador de internet (browser), quando um site é
        visitado pelo utilizador.
      </p>
      <p>
        A colocaçáo de cookies não só ajudará o site a reconhecer o dispositivo
        do utilizador na próxima vez que este o visita mas também, com
        frequência, será imprescindível para o funcionamento do mesmo.
      </p>
      <p>
        Os cookies usados por A devstudio, em todos os seus sites, não recolhem
        informações pessoais que permitam identificar o utilizador. Guardam
        informações genéricas, designadamente a forma ou local/país de acesso
        dos utilizadores e o modo como usam os sites, entre outros.
      </p>
      <p>
        Os cookies retêm apenas informaçáo relacionada com as suas preferências.
        O utilizador pode, a qualquer momento e através do seu navegador de
        internet (browser), decidir ser notificado sobre a recepção de cookies,
        bem como bloquear a respectiva entrada no seu sistema. Salienta-se, no
        entanto, que a recusa de uso de cookies no site pode resultar na
        impossibilidade de acesso a algumas das suas áreas e não permitir toda a
        experiência de navegação.
      </p>
      <h2>Funcionalidade dos cookies</h2>
      <p>
        Em geral, os cookies são usados para melhorar a experiência de navegação
        do utilizador, aumentando a rapidez e eficiência de resposta. Eliminam a
        necessidade de introduzir repetidamente as mesmas informações e ajudam a
        determinar que as mensagens publicitárias que o utilizador vê são
        relevantes e se ajustam aos seus interesses, ou seja, a utilidade,
        interesse e o número de utilizações dos sites.
      </p>
      <h2>Diferentes tipos de cookies</h2>
      <p>Cada cookie utilizado tem uma função e uma data de validade.</p>
      <p>Relativamente à função, os cookies utilizados podem ser:</p>
      <ul>
        <li>
          <p>
            <u>Cookies essenciais</u> – Alguns cookies são essenciais para
            aceder a áreas específicas dos sites devstudio. Permitem a navegação
            no site e a utilização das suas aplicações, tal como o acesso a
            áreas seguras do site através de login. Sem estes cookies, os
            serviços que o exijam não podem ser prestados.
          </p>
        </li>
        <li>
          <p>
            <u>Cookies de funcionalidade</u> – Os cookies de funcionalidade
            permitem relembrar as preferências do utilizador relativamente à
            navegação no site. Assim, não necessita de o reconfigurar e
            personalizar cada vez que o visita.
          </p>
        </li>

        <li>
          <p>
            <u>Cookies analíticos</u> – Estes cookies são utilizados para
            analisar a forma como os utilizadores usam o site, permitindo
            destacar artigos ou serviços que podem ser do interesse dos
            utilizadores, e monitorizar o desempenho do site, conhecendo quais
            as páginas mais populares, qual o método de ligação entre páginas
            que é mais eficaz ou para determinar a razão de algumas páginas
            estarem a receber mensagens de erro. Estes cookies são utilizados
            apenas para efeitos de criação e análise estatística, sem nunca
            recolher informação de carácter pessoal.
          </p>
        </li>
      </ul>
      <p>
        Assim, A devstudio pode fornecer uma experiência de elevada qualidade ao
        personalizar a sua oferta e, rapidamente, identificar e corrigir
        quaisquer problemas que surjam. Relativamente à data de validade, os
        cookies utilizados podem ser:
      </p>
      <ul>
        <li>
          <p>
            <u>Cookies permanentes</u> – Ficam armazenados nos seus dispositivos
            de acesso (computador, telemóvel/ smartphone ou tablet), ao nível do
            navegador de internet (browser), e são usados sempre que o
            utilizador visita novamente o site. Em geral, são usados para
            direccionar a navegação de acordo com os interesses do utilizador,
            permitindo ao devstudio prestar um serviço mais personalizado.
          </p>
        </li>
        <li>
          <p>
            <u>Cookies de sessão</u> – São temporários, os cookies são gerados e
            estão disponíveis até encerrar a sessão. Da próxima vez que o
            utilizador aceder ao seu navegador de internet (browser) os cookies
            já não estarão armazenados. A informação obtida permite gerir as
            sessões, identificar problemas e fornecer uma melhor experiência de
            navegação. Bloquear o uso de cookies O utilizador pode desactivar
            parte ou a totalidade dos cookies a qualquer momento. Ao desactivar
            os cookies o site devstudio pode não funcionar correctamente.
          </p>
        </li>
      </ul>
    </section>
  );
};

export default IndexPage;
